import React, { useEffect } from 'react';
import { Card, Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { useFetch } from '../../../utils/useFetch';
import { initializePaddle, getPaddleInstance } from '@paddle/paddle-js';
import './Subscription.css'; // Import the CSS file
import { useAuth0 } from '@auth0/auth0-react';

const REACT_APP_PADDLE_ENV = process.env.REACT_APP_PADDLE_ENV;

const Subscription = ({ show, onClose }) => {
  const { data, error, loading } = useFetch('/account/list_products/');
  const { user } = useAuth0();

  
  useEffect(() => {
    initializePaddle({ environment: REACT_APP_PADDLE_ENV, token: "test_acf073b7d3a4db6466fbad76d82" }); // Replace with your vendor ID
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const annualProduct = data?.products?.annual || {};
  const monthlyProduct = data?.products?.monthly || {};

  const handleCheckout = (priceId) => {
    const Paddle = getPaddleInstance();
    Paddle?.Checkout.open({
      settings: {
      theme: 'dark',
      },
      items: [{ priceId: priceId, quantity: 1 }],
      customer: { email: user.email },
      customData: { user_id: user.sub },
    });
  };

  return (
    <Modal show={show} onHide={onClose} size={'lg'} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Subscribe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <p>One plan, two pricing options.</p>
          <Row>
            <Col sm={12} md={6}>
              <Card className="bg-light h-100 border-0 py-3 py-sm-4 py-lg-5">
                <Card.Body className="text-center">
                  <div className="display-5 text-dark mb-1">
                    <span className="fs-3 text-muted dollar-sign">$</span>{monthlyProduct.price || 'N/A'}
                  </div>
                  <div className="text-muted mb-5">per month</div>
                  <div className="fs-lg pb-4 mb-3">7 Day Free Trial</div>
                </Card.Body>
                <Card.Footer className="border-0 text-center pt-0 pb-4">
                  <Button variant="outline-primary" size="lg" onClick={() => handleCheckout(monthlyProduct.price_id)}>Monthly</Button>
                </Card.Footer>
              </Card>
            </Col>
            <Col sm={12} md={6}>
              <Card className="bg-light h-100 border-0 py-3 py-sm-4 py-lg-5">
                <Card.Body className="text-center">
                  <div className="display-5 text-dark mb-1">
                    <span className="fs-3 text-muted dollar-sign">$</span>{annualProduct.price || 'N/A'}
                  </div>
                  <div className="text-muted mb-5">per year</div>
                  <div className="fs-lg pb-4">14 Day Free Trial</div>
                  <div className="fs-lg pb-4 mb-3">Commit and Save</div>
                </Card.Body>
                <Card.Footer className="border-0 text-center pt-0 pb-4">
                  <Button variant="outline-primary" size="lg" onClick={() => handleCheckout(annualProduct.price_id)}>Annual</Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default Subscription;
