import React, { useState, useEffect } from "react";
import './MainPage.css';
import SymbolPlate from "./subcomps/SymbolPlate";
import ForecastArea from "./subcomps/ForecastArea";
import { Row, Col } from 'react-bootstrap';

const MainPage = () => {
  const [selectedSymbol, setSelectedSymbol] = useState(null);

  useEffect(() => {
    const savedSymbol = localStorage.getItem('selectedSymbol');
    if (savedSymbol) {
      setSelectedSymbol(savedSymbol);
    }
  }, []);

  const handleSymbolChange = (symbol) => {
    setSelectedSymbol(symbol);
    localStorage.setItem('selectedSymbol', symbol);
  };

  return (
    <div className="main-page-container" style={{ position: 'relative' }}>
      <Row>
        <SymbolPlate
          selectedSymbol={selectedSymbol}
          setSelectedSymbol={handleSymbolChange}
        />
      </Row>
      <Row className="forecast-area-row">
        <Col style={{ display: 'flex', justifyContent: 'center', margin: 'auto', width: '90%', height: '100%' }} className="forecast-area-container">
          <ForecastArea 
            selectedSymbol={selectedSymbol}
          />
        </Col>
      </Row>
    </div>
  );
}

export default MainPage;