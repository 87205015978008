import React, { useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './App.css';
import { Row, Container, Spinner } from 'react-bootstrap';
import TopBar from './components/nav/TopBar';
import MainPage from './components/main/MainPage';
import AccountPage from './components/account/AccountPage';
import { SubscriptionProvider } from './contexts/SubscriptionContext';


const App = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const setTheme = (theme) => {
    // Set the dark theme explicitly
    document.documentElement.setAttribute('data-bs-theme', 'dark');
  };

  useEffect(() => {
    // Force the dark theme when the component is mounted
    setTheme('dark');
  }, []);

  // Automatically push the user to login if not authenticated
  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return (
      <div>
        <div style={{ position:'fixed', height:'100vh', width:'100vw', top:'0', left:'0', display: 'flex', justifyContent:'center', alignItems:'center'}}>
          <Spinner  color="primary" size="3rem" />
        </div>
      </div>
    )
  }

  if (isAuthenticated) {
    return (
      <SubscriptionProvider>
        <Router>
          <div className='app-container' style={{  }}>
            <TopBar />
            <Row className='app-master-row m-0 p-0'>
              <Container fluid style={{ height: '100%', padding: '0' }}> 
                <Routes>
                  <Route path="/" element={<MainPage />} />
                  <Route path="/account/*" element={<AccountPage />} />
                </Routes>
              </Container>
            </Row>
          </div>
        </Router>
      </SubscriptionProvider>
    );
  }

  return null;
};

export default App;
