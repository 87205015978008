import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useFetch = (endpoint = {}) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }

    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://api.tckrai.com',  // Pass scope if necessary
        },
      });

      const url = `${API_BASE_URL}${endpoint}`;

      // Make an authenticated request to the API
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setData(response.data);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [isAuthenticated, getAccessTokenSilently, endpoint]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, loading, refetch: fetchData };
};

export const useFetchCustomerID = () => {
  return useFetch('/account/get_or_create_customer_id/');
};
