import React, { useState, useEffect, useCallback, useRef } from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import './ForecastChart.css';
import { Row } from 'react-bootstrap';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);

const ForecastChart = ({ selectedSymbol, forecastChartData }) => {
  const [chartOptions, setChartOptions] = useState(null);
  const [currentDataPoint, setCurrentDataPoint] = useState(null);
  const [latestDataPoint, setLatestDataPoint] = useState(null);
  const [forecastData, setForecastData] = useState([]);
  const [latestForecastData, setLatestForecastData] = useState([]);
  const chartContainerRef = useRef(null); // Ref for the container
  const [forecastTimeout, setForecastTimeout] = useState(null);

  // Initialize currentDataPoint with the latest data point
  useEffect(() => {
    if (forecastChartData && forecastChartData.length > 0) {
      const latest = forecastChartData[forecastChartData.length - 1];
      setLatestDataPoint(latest);
      setCurrentDataPoint(latest);
      if (latest.forecasts) {
        const forecastData = [];
        const baseTimestamp = latest.x;
        Object.keys(latest.forecasts).forEach(quantile => {
          latest.forecasts[quantile].forEach((value, index) => {
            forecastData.push({
              x: baseTimestamp + (index + 1) * 60 * 1000,
              y: value,
              quantile: quantile
            });
          });
        });
        setLatestForecastData(forecastData);
      }
    }
  }, [forecastChartData]);

  const createOptions = useCallback((data) => {
    const vwapData = data.map(item => [item.x, item.vwap]).filter(item => item[1] !== undefined);

    return {
      time: {
        useUTC: false
      },
      chart: {
        backgroundColor: 'var(--si-body-bg)',
        color: 'var(--si-body-color)',
        zoomType: null,
        panning: {
          enabled: true,
          type: 'x' // Enable panning on the x-axis
        },
        marginRight: 0,
        marginLeft: 0,
        zooming: {
          mouseWheel: {
            enabled: true, // Enable zooming with the mouse scroll wheel
          },
          pinchType: 'x' // Enable zooming with screen pinching
        },
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: { 
        enabled: true,
        height: 18,
       },
      scrollbar: { enabled: false },
      credits: { enabled: false },
      exporting: { enabled: false },
      tooltip: {
        shape: 'square',
        headerShape: 'callout',
        borderWidth: 0,
        shadow: false,
        backgroundColor: 'var(--si-body-bg)',
        style: {
          color: 'var(--si-body-color)',
        },
        positioner: function (width, height, point) {
          const chart = this.chart;
  
          if (point.formatPrefix === 'point') {
            return {
              x: point.series.chart.plotLeft,
              y: point.series.yAxis.top - chart.plotTop
            };
          }
  
          return {
            x: Math.max(
              // Left side limit
              chart.plotLeft,
              Math.min(
                point.plotX + chart.plotLeft - width / 2,
                // Right side limit
                chart.chartWidth - width - chart.marginRight
              )
            ),
            y: point.plotY
          };
        }
      },
      stockTools: {
        gui: {
          enabled: false
        }
      },
      title: {
        text: '',
      },
      yAxis: [
        {
          gridLineColor: 'var(--si-border-color-translucent)',
          labels: {
            x: 0,
            style: {
              color: 'var(--si-body-color)',
              fontSize: '10px',
            },
          },
        },
        {
          gridLineColor: 'var(--si-border-color-translucent)',
          labels: {
            enabled: false,
          },
          opposite: true,
          linkedTo: 0,
        }
      ],
      xAxis: {
        labels: {
          style: {
            color: 'var(--si-body-color)',
          },
        },
        minRange: 20 * 60 * 1000, // 20 minutes
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              mouseOver: function () {
                if (this.series.type === 'candlestick') {
                  setCurrentDataPoint(this);
                  if (this.forecasts) {
                    const forecastData = [];
                    const baseTimestamp = this.x;
                    Object.keys(this.forecasts).forEach(quantile => {
                      this.forecasts[quantile].forEach((value, index) => {
                        forecastData.push({
                          x: baseTimestamp + (index + 1) * 60 * 1000,
                          y: value,
                          quantile: quantile
                        });
                      });
                    });
                    setForecastData(forecastData);
                  }
                  if (forecastTimeout) {
                    clearTimeout(forecastTimeout);
                    setForecastTimeout(null);
                  }
                }
              },
              mouseOut: function () {
                const timeout = setTimeout(() => {
                  setCurrentDataPoint(latestDataPoint);
                  setForecastData([]);
                }, 2000);
                setForecastTimeout(timeout);
              }
            }
          },
          states: {
            inactive: {
              enabled: false
            }
          },
          animation: false // Disable animation for the series
        }
            },
            series: [
        {
          type: 'candlestick',
          name: selectedSymbol,
          data: data.filter(item => item.open !== undefined).map(item => ({
            x: item.x,
            open: item.open,
            high: item.high,
            low: item.low,
            close: item.close,
            volume: item.volume,
            forecasts: item.forecasts,
          })),
          color: '#eb1515',
          upColor: 'transparent',
          lineColor: '#eb1515',
          upLineColor: '#1a9a49',
          lineWidth: 1,
          dataGrouping: {
            enabled: false,
          },
          animation: false, // Disable animation for the candlestick series
        },
        {
          type: 'line',
          name: 'VWAP',
          data: vwapData,
          color: '#0d6efd',
          lineWidth: 1,
          enableMouseTracking: false,
          animation: false, // Disable animation for the VWAP series
        },
        {
          type: 'line',
          name: 'Forecast Midpoint',
          data: forecastData.filter(item => item.quantile === 'forecast_0.5'),
          color: 'rgba(134, 178, 206, 0.9)',
          dashStyle: 'Dash',
          yAxis: 1,
          enableMouseTracking: false,
          zIndex: 0,
          animation: false, // Disable initial animation for the Forecast Midpoint series
        },
        {
          type: 'arearange',
          name: 'interval 50',
          data: forecastData.filter(item => item.quantile === 'forecast_0.25' || item.quantile === 'forecast_0.75').map(item => ({
            x: item.x,
            low: item.quantile === 'forecast_0.25' ? item.y : undefined,
            high: item.quantile === 'forecast_0.75' ? item.y : undefined,
          })).reduce((acc, item) => {
            const existing = acc.find(i => i.x === item.x);
            if (existing) {
              existing.low = item.low !== undefined ? item.low : existing.low;
              existing.high = item.high !== undefined ? item.high : existing.high;
            } else {
              acc.push(item);
            }
            return acc;
          }, []),
          color: 'rgba(134, 178, 206, 0.6)',
          fillOpacity: 0.2,
          yAxis: 1,
          enableMouseTracking: false,
          zIndex: 0,
          animation: false, // Disable initial animation
        },
        {
          type: 'arearange',
          name: 'interval 90',
          data: forecastData.filter(item => item.quantile === 'forecast_0.05' || item.quantile === 'forecast_0.95').map(item => ({
            x: item.x,
            low: item.quantile === 'forecast_0.05' ? item.y : undefined,
            high: item.quantile === 'forecast_0.95' ? item.y : undefined,
          })).reduce((acc, item) => {
            const existing = acc.find(i => i.x === item.x);
            if (existing) {
              existing.low = item.low !== undefined ? item.low : existing.low;
              existing.high = item.high !== undefined ? item.high : existing.high;
            } else {
              acc.push(item);
            }
            return acc;
          }, []),
          color: 'rgba(134, 178, 206, 0.4)',
          fillOpacity: 0.1,
          yAxis: 1,
          enableMouseTracking: false,
          zIndex: 0,
          animation: false, // Disable initial animation
        },
        {
          type: 'line',
          name: 'Latest Forecast Midpoint',
          data: latestForecastData.filter(item => item.quantile === 'forecast_0.5'),
          color: 'rgba(13, 161, 255, 0.9)',
          dashStyle: 'Dash',
          enableMouseTracking: false,
          zIndex: 0,
          animation: false,
          events: {
            afterAnimate: function () {
              const chart = this.chart;
              const series = chart.series.find(s => s.name === 'Latest Forecast Midpoint'); // Find the Forecast Midpoint series
              if (!series) {
                return;
              }
        
              // Highcharts draws the series’ path as an SVG <path> element with class "highcharts-graph"
              // Once the chart is loaded, you can grab that path:
              if (!series.graph) {
                return;
              }
              const graphPath = series.graph.element;
              if (!graphPath) {
                return;
              }
        
              // Set up dash styling - strokeDasharray: "length, gap"
              // For a repeated arrow effect, you'll need to experiment or define a small arrow shape (see below).
              // graphPath.setAttribute('stroke-dasharray', '10 24');
              // graphPath.setAttribute('stroke-dashoffset', 0);
        
              // Kick off an animation loop to increment or decrement the dash offset
              let offset = 0;
              const animate = () => {
                offset -= 0.25; // slow down the animation
                graphPath.setAttribute('stroke-dashoffset', offset);
                requestAnimationFrame(animate);
              };
              requestAnimationFrame(animate);
            }
          }
        },
        {
          type: 'arearange',
          name: 'Latest interval 50',
          data: latestForecastData.filter(item => item.quantile === 'forecast_0.25' || item.quantile === 'forecast_0.75').map(item => ({
            x: item.x,
            low: item.quantile === 'forecast_0.25' ? item.y : undefined,
            high: item.quantile === 'forecast_0.75' ? item.y : undefined,
          })).reduce((acc, item) => {
            const existing = acc.find(i => i.x === item.x);
            if (existing) {
              existing.low = item.low !== undefined ? item.low : existing.low;
              existing.high = item.high !== undefined ? item.high : existing.high;
            } else {
              acc.push(item);
            }
            return acc;
          }, []),
          color: 'rgba(13, 161, 255, 0.6)',
          fillOpacity: 0.2,
          enableMouseTracking: false,
          zIndex: 0,
          animation: false,
          
        },
        {
          type: 'arearange',
          name: 'Latest interval 90',
          data: latestForecastData.filter(item => item.quantile === 'forecast_0.05' || item.quantile === 'forecast_0.95').map(item => ({
            x: item.x,
            low: item.quantile === 'forecast_0.05' ? item.y : undefined,
            high: item.quantile === 'forecast_0.95' ? item.y : undefined,
          })).reduce((acc, item) => {
            const existing = acc.find(i => i.x === item.x);
            if (existing) {
              existing.low = item.low !== undefined ? item.low : existing.low;
              existing.high = item.high !== undefined ? item.high : existing.high;
            } else {
              acc.push(item);
            }
            return acc;
          }, []),
          color: 'rgba(13, 161, 255, 0.4)',
          fillOpacity: 0.1,
          yAxis: 1,
          enableMouseTracking: false,
          zIndex: 0,
          animation: false,
        },
      ],
    };
  }, [selectedSymbol, latestDataPoint, forecastData, latestForecastData, forecastTimeout]);

  useEffect(() => {
    const data = (forecastChartData || []).map(item => ({
      x: item.x,
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close,
      volume: item.volume,
      vwap: item.vwap,
      forecasts: item.forecasts,
    }));

    setChartOptions(createOptions(data));
  }, [forecastChartData, createOptions]);

  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current || !forecastChartData?.length) return;

    const chart = chartRef.current.chart;
    const xAxis = chart.xAxis[0];
    const { min, max, dataMax } = xAxis.getExtremes();

    // Compute the visible range and a threshold (10% of the visible window)
    const visibleRange = max - min;
    const threshold = visibleRange * 0.1; // Adjust this factor as needed

    // Latest x-value from forecastChartData
    const newestX = forecastChartData[forecastChartData.length - 1].x;
    // Latest x-value from the forecast (if available)
    const latestForecastX =
      latestForecastData.length > 0
        ? latestForecastData[latestForecastData.length - 1].x
        : newestX;
    // Use the larger of the two as the “newest” x-value
    const maxX = Math.max(newestX, latestForecastX);

    // Only auto-shift if the view is within the threshold of the right edge.
    if (dataMax - max < threshold) {
      // Calculate how much to shift.
      const shift = maxX - max + 10; // The buffer "10" can be tuned as needed.
      const newMin = min + shift;
      const newMax = max + shift;
      xAxis.setExtremes(newMin, newMax, true, false);
    }
  }, [forecastChartData, latestForecastData]);

  return (
    <div className="forecast-chart-container" ref={chartContainerRef}>
      {chartOptions && (
        <Row className="forecast-chart-row">
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            constructorType={'stockChart'}
            options={chartOptions}
          />
        </Row>
      )}
      {currentDataPoint && (
        <Row>
          {/* <div className="chart-readout">
            <span className="readout-item">
              <div style={{ color:'var(--si-tertiary-color)' }}></div> {format(new Date(currentDataPoint.x), 'hh:mm a')}
            </span>
            {currentDataPoint.open && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>O: </div>{currentDataPoint.open.toFixed(2)}</span>}
            {currentDataPoint.high && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>H: </div>{currentDataPoint.high.toFixed(2)}</span>}
            {currentDataPoint.low && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>L: </div>{currentDataPoint.low.toFixed(2)}</span>}
            {currentDataPoint.close && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>C: </div>{currentDataPoint.close.toFixed(2)}</span>}
            {currentDataPoint.volume && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>V: </div>{currentDataPoint.volume.toLocaleString()}</span>}
            {currentDataPoint.label && <span className="readout-item"><div style={{ color:'var(--si-tertiary-color)' }}>P: </div><strong>{currentDataPoint.label}</strong></span>}
          </div> */}
        </Row>
      )}
    </div>
  );
};

export default ForecastChart;
