import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import { Button, Badge } from 'react-bootstrap';
import MainLogo from './MainLogo';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';

function OffCanvasNav() {
  const [show, setShow] = useState(false);
  
  // Destructure logout function from useAuth0 hook
  const { logout } = useAuth0();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="outline-dark" style={{ margin:'0',padding:'10px 12px' }} onClick={handleShow}>
        <box-icon name='menu' color='var(--si-body-color)'></box-icon>
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <MainLogo style={{ height: '30px', marginRight: '10px' }}/>
            <Badge bg='faded-primary' text='gradient-primary'>Beta</Badge>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <Nav className="flex-column">
              <Nav.Item>
                <Nav.Link as={NavLink} to="/" activeClassName="active">Chart</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to="/account" activeClassName="active">Account</Nav.Link>
              </Nav.Item>
            </Nav>
        </Offcanvas.Body>
        <Offcanvas.Header>
            <Button className="w-100" variant="outline-primary" onClick={() => logout({ returnTo: window.location.origin })}>
              Logout
            </Button>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
}

export default OffCanvasNav;
