import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSubscriptionStatus } from '../../../contexts/SubscriptionContext';
import Subscription from './Subscription';
import { Button } from 'react-bootstrap';
import { useFetch } from '../../../utils/useFetch';

const AccountDetails = () => {
  const { subscriptionStatus, error, loading, refreshSubscriptionStatus } = useSubscriptionStatus();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { data: portalData, error: portalError, loading: portalLoading } = useFetch('/account/customer_portal_link/');

  useEffect(() => {
    refreshSubscriptionStatus();
  }, [refreshSubscriptionStatus]);

  if (loading || portalLoading) {
    return <p>Loading...</p>;
  }

  if (error || portalError) {
    return <p>Error: {error || portalError}</p>;
  }

  const handleSubscribeClick = () => {
    setShowSubscriptionModal(true);
  };

  const handleCloseModal = () => {
    setShowSubscriptionModal(false);
  };

  return (
    <div>
      <h2>Account Details</h2>
      {portalData?.portal_link && <p>To view payment history and modify your subscription, follow the link to our payment partner <Link to={portalData.portal_link} target="_blank" rel="noopener noreferrer">here</Link></p>}
      {subscriptionStatus ? (
        <p>You have an active subscription.</p>
      ) : (
        <p>
          You do not have an active subscription. <Button variant="link" onClick={handleSubscribeClick}>Click here to subscribe</Button>
        </p>
      )}
      <Subscription show={showSubscriptionModal} onClose={handleCloseModal} />
    </div>
  );
};

export default AccountDetails;
