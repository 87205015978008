import React, { useEffect, useState, useRef } from "react";
import { useSubscriptionStatus } from '../../../contexts/SubscriptionContext';
import { Card, Row, Button, Spinner } from 'react-bootstrap';
import { useFetchManual } from "../../../utils/useFetchManual";
import ForecastChart from "./ForecastChart";
import Subscription from "../../account/views/Subscription";

const ForecastArea = ({ selectedSymbol }) => {
  const { subscriptionStatus, loading: subscriptionLoading, refreshSubscriptionStatus } = useSubscriptionStatus();
  const { data: forecastChartData, loading: forecastChartDataLoading, error: forecastChartDataError, fetchData } = useFetchManual();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [allForecastData, setAllForecastData] = useState([]);
  const prevSelectedSymbol = useRef();

  console.log(forecastChartDataError);
  console.log(forecastChartDataLoading);

  useEffect(() => {
    if (selectedSymbol && selectedSymbol !== prevSelectedSymbol.current) {
      fetchData(`/mdata/forcasting-chart-data/${selectedSymbol}`);
      prevSelectedSymbol.current = selectedSymbol;
    }
  }, [selectedSymbol, fetchData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedSymbol && selectedSymbol === prevSelectedSymbol.current) {
        fetchData(`/mdata/forcasting-chart-data/${selectedSymbol}`);
      }
    }, 5000); // 1000 ms = 1 second
    
    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [selectedSymbol, fetchData]);

  useEffect(() => {
    refreshSubscriptionStatus();
  }, [refreshSubscriptionStatus]);

  useEffect(() => {
    if (forecastChartData && forecastChartData.length > 0) {
      setAllForecastData(forecastChartData);
    }
  }, [forecastChartData]);

  const handleSubscribeClick = () => {
    setShowSubscriptionModal(true);
  };

  const handleCloseModal = () => {
    setShowSubscriptionModal(false);
  };

  return (
    <>
      <ForecastChart
        forecastChartData={allForecastData}
        selectedSymbol={selectedSymbol}
      />
      {subscriptionLoading ? (
        <Card style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 500 }}>
          <Card.Body className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
          </Card.Body>
        </Card>
      ) : (
        !subscriptionStatus && (
          <Card style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 500 }}>
            <Card.Body>
              <Card.Title>Subscription Required</Card.Title>
              <Card.Text>
                You need an active subscription to view live forecasts!
              </Card.Text>
              <Row>
                <Button variant="outline-primary" onClick={handleSubscribeClick}>Subscribe</Button>
              </Row>
            </Card.Body>
          </Card>
        )
      )}
      <Subscription show={showSubscriptionModal} onClose={handleCloseModal} />
    </>
  );
}

export default ForecastArea;