import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import AccountDetails from "./views/AccountDetails";
import Security from "./views/Security";

const AccountPage = () => {
  return (
    <Container className="pt-5" style={{ height: "100%" }}>
      <Row>
        <Col md={8} className="offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
          <Routes>
            <Route path="/" element={<AccountDetails />} />
            <Route path="security" element={<Security />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
}

export default AccountPage;